import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import {
    SharedRelatedItemsWrapper,
    type ChildrenProps as GridProps,
} from '../SharedRelatedItemsWrapper/SharedRelatedItemsWrapper';
import { SharedItemTile } from '../../shared/SharedItemTile/SharedItemTile';
import { SharedMobileRelatedItem } from '../../shared/SharedMobileRelatedItem/SharedMobileRelatedItem';
import { recentSales } from '../../shared/sharedMessages';
import { RESULTS_FIRST_ROW_COUNT } from '../../constants/sbConstants';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { useUserSessionRegions } from 'dibs-regional-info/exports/regionalInfoHelpers';
import {
    trackItemsImpression,
    trackItemClick,
    type Item as ItemTrackProps,
} from '../SharedRelatedItemsWrapper/helpers/sharedRelatedItemsWrapperTracking';

import { type SharedRecentSales_viewer$key } from './__generated__/SharedRecentSales_viewer.graphql';
import { type SharedRecentSales_itemSearch$key } from './__generated__/SharedRecentSales_itemSearch.graphql';
import { type SharedRecentSales_user$key } from './__generated__/SharedRecentSales_user.graphql';

type Props = {
    viewer: SharedRecentSales_viewer$key;
    itemSearch: SharedRecentSales_itemSearch$key;
    user: SharedRecentSales_user$key;
    isMobile: boolean;
    isClient: boolean;
    hideRecentSoldPrice?: boolean;
    lazyLoadStartIndex?: number;
};

export const SharedRecentSales: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    user: userRef,
    isMobile,
    isClient,
    hideRecentSoldPrice,
    lazyLoadStartIndex,
}) => {
    const viewer = useFragment(
        graphql`
            fragment SharedRecentSales_viewer on Viewer {
                ...SharedItemTile_viewer
            }
        `,
        viewerRef
    );

    const itemSearch = useFragment(
        graphql`
            fragment SharedRecentSales_itemSearch on ItemSearchQueryConnection
            @argumentDefinitions(priceBookName: { type: "String" }) {
                pageType
                ...SharedItemTile_itemSearch
                soldItemResults: soldResults(first: 28) {
                    edges {
                        node {
                            item {
                                serviceId
                                ...SharedMobileRelatedItem_item
                                    @arguments(priceBookName: $priceBookName)
                                ...SharedItemTile_item
                                    @arguments(
                                        isTrade: $isTrade
                                        priceBookName: $priceBookName
                                        showSeller: $showSeller
                                    )
                                ...sharedRelatedItemsWrapperTracking_item
                                ...SharedRelatedItemsWrapper_item
                            }
                        }
                    }
                }
            }
        `,
        itemSearchRef
    );

    const user = useFragment(
        graphql`
            fragment SharedRecentSales_user on User {
                ...SharedRelatedItemsWrapper_user
                ...SharedMobileRelatedItem_user
                ...SharedItemTile_user
            }
        `,
        userRef
    );

    const { soldItemResults, pageType } = itemSearch;
    const items = soldItemResults?.edges?.map(edge => edge?.node?.item).filter(filterFalsy) || [];
    const userRegions = useUserSessionRegions() || [];

    const onItemClick = ({ item, index }: { item: ItemTrackProps; index: number }): void => {
        trackItemClick({
            items: [item],
            startIndex: index,
            list: `${pageType} related sold items`,
            userRegions,
        });
    };

    if (!items.length) {
        return null;
    }

    return (
        <SharedRelatedItemsWrapper
            user={user}
            header={recentSales}
            isMobile={isMobile}
            isClient={isClient}
            items={items}
            showSwiper={isMobile}
            initialCount={RESULTS_FIRST_ROW_COUNT}
            onItemsImpression={({ startIndex, itemsLengthToShow }) => {
                if (items?.length) {
                    trackItemsImpression({
                        items: [...items].splice(startIndex, itemsLengthToShow),
                        startIndex,
                        list: `${pageType} related sold items`,
                        userRegions,
                    });
                }
            }}
        >
            {({ itemClassName, ...itemProps }: GridProps) =>
                items.map((item, index) => {
                    return isMobile ? (
                        <SharedMobileRelatedItem
                            key={item.serviceId}
                            itemsPerRow={lazyLoadStartIndex}
                            index={index}
                            item={item}
                            user={user}
                            ymalHeartsEnabled
                            onClick={() => onItemClick({ item, index })}
                            {...itemProps}
                            {...itemClassName(index)}
                            hideRecentSoldPrice={hideRecentSoldPrice}
                            isClient={isClient}
                        />
                    ) : (
                        <SharedItemTile
                            key={item.serviceId}
                            lazyLoadStartIndex={lazyLoadStartIndex}
                            index={index}
                            viewer={viewer}
                            item={item}
                            itemSearch={itemSearch}
                            user={user}
                            showCreatorName
                            showSellerName={false}
                            fetchFolderOnMouseOver
                            onClick={() => onItemClick({ item, index })}
                            {...itemProps}
                            {...itemClassName(index)}
                            hideRecentSoldPrice={hideRecentSoldPrice}
                        />
                    );
                })
            }
        </SharedRelatedItemsWrapper>
    );
};
