import PropTypes from 'prop-types';
import { useIntl } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { useServerVarsContext } from '../../../global/ServerVarsContext/ServerVarsContext';
import { isDimensionFilter } from '../sbSharedRefineMenuHelpers';
import { useMeasurementUnit } from '../../useMeasurementUnit';
import { getPreviouslyAppliedCategory, CATEGORY_LEVEL_1 } from '../../../utils/categoryHelpers';
import { VERTICALS } from '../../../constants/uriConstants';

// components
import { SbSharedRefineMenuFacet } from '../SbSharedRefineMenuFacet/SbSharedRefineMenuFacet';

function SbSharedRefineMenuFiltersComponent({
    user,
    viewer,
    filters,
    currency,
    isClient,
    filterMap,
    itemSearch,
    initiallyExpanded,
}) {
    let dimensionsDisplayed = false;
    const { isMobile } = useServerVarsContext();
    const intl = useIntl();
    const [measurementUnit] = useMeasurementUnit({ user, itemSearch });
    const isArt = itemSearch.topCategoryL1 === VERTICALS.ART;

    return filters?.map(filter => {
        const {
            values,
            totalCount,
            name: filterName,
            localizedFilterName: title,
            originalFilterName,
            localizedFilterValue,
        } = filter;
        const { component: FilterComponent, title: FilterTitle } = filterMap[filterName] || {};
        const titleOverride = FilterTitle && intl.formatMessage(FilterTitle);
        const trackingTrigger = FilterTitle ? FilterTitle.defaultMessage : originalFilterName;
        /**
         * height, width, length and depth are returned as separate filters but need to be
         * displayed in one "Dimensions" facet.  When the first valid dimension type filter is hit
         * we will setup the dimensions facet.  Once the dimensions facet is setup we will
         * ignore the rest of the dimension filters.
         *
         * The dimensions filter component will account and setup all available dimension filters in one facet
         *
         * Art filter uses a custom component, in art category dimensions will be grouped with artSize
         */

        if (isDimensionFilter(filterName)) {
            if (isArt || dimensionsDisplayed) {
                return null;
            }
            dimensionsDisplayed = true;
        }

        const appliedFilter = isMobile
            ? itemSearch.appliedFilters.find(f => f.name === filterName)
            : null;
        let selectedValues = appliedFilter ? appliedFilter.values : [];

        if (filterName === CATEGORY_LEVEL_1 && selectedValues.length) {
            const lowestCategory = getPreviouslyAppliedCategory(itemSearch.appliedFilters);
            if (lowestCategory) {
                selectedValues = lowestCategory.values;
            }
        }
        const maxStatsRange = isDimensionFilter(filterName) ? values?.[0]?.displayName : '';

        return (
            FilterComponent && (
                <SbSharedRefineMenuFacet
                    key={filterName}
                    storageFacetKey={filterName}
                    title={titleOverride || title}
                    trackingTrigger={trackingTrigger}
                    titleMessage={title}
                    initiallyExpanded={initiallyExpanded(filterName)}
                    selectedValues={selectedValues}
                    measurementUnit={measurementUnit}
                    maxStatsRange={maxStatsRange}
                    currency={currency}
                    localizedFilterValue={localizedFilterValue}
                    appliedFiltersComponent={null}
                >
                    <FilterComponent
                        user={user}
                        viewer={viewer}
                        values={values}
                        filter={filter}
                        filters={filters}
                        currency={currency}
                        isClient={isClient}
                        totalCount={totalCount}
                        filterName={filterName}
                        itemSearch={itemSearch}
                        shouldUseExpandableList={false}
                    />
                </SbSharedRefineMenuFacet>
            )
        );
    });
}

SbSharedRefineMenuFiltersComponent.propTypes = {
    user: PropTypes.object,
    isClient: PropTypes.bool,
    filters: PropTypes.any,
    viewer: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    filterMap: PropTypes.object.isRequired,
    itemSearch: PropTypes.object.isRequired,
    initiallyExpanded: PropTypes.func.isRequired,
    uriRef: PropTypes.string,
    isTrade: PropTypes.bool,
};

export const SbSharedRefineMenuFilters = createFragmentContainer(
    SbSharedRefineMenuFiltersComponent,
    {
        viewer: graphql`
            fragment SbSharedRefineMenuFilters_viewer on Viewer {
                ...SbSharedRefineMenuFilterMetal_viewer
                ...SbSharedRefineMenuFilterStone_viewer
                ...SbSharedRefineMenuFilterOrigin_viewer
                ...SbSharedRefineMenuFilterCreator_viewer
                ...SbSharedRefineMenuFilterMaterial_viewer
                ...SbSharedRefineMenuFilterArtSubject_viewer
                ...SbSharedRefineMenuFilterItemLocation_viewer
                ...SbSharedRefineMenuFilterShipsFrom_viewer
                ...SbRespRefineMenuFilterStoneCut_viewer
                ...SbMobileRefineMenuFilterStoneCut_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilters_itemSearch on ItemSearchQueryConnection {
                ...SbRespRefineMenuFilterPrice_itemSearch
                ...SbSharedRefineMenuFilterStone_itemSearch
                ...SbSharedRefineMenuFilterMetal_itemSearch
                ...SbSharedRefineMenuFilterColor_itemSearch
                ...SbMobileRefineMenuFilterPrice_itemSearch
                ...SbSharedRefineMenuFilterStyle_itemSearch
                ...SbSharedRefineMenuFilterArtSubject_itemSearch
                ...SbSharedRefineMenuFilterOrigin_itemSearch
                ...SbSharedRefineMenuFilterPeriod_itemSearch
                ...SbRespRefineMenuFilterCategory_itemSearch
                ...SbRespRefineMenuFilterStoneCut_itemSearch
                ...SbSharedRefineMenuFilterCreator_itemSearch
                ...SbMobileRefineMenuFilterStoneCut_itemSearch
                ...SbMobileRefineMenuFilterCategory_itemSearch
                ...SbSharedRefineMenuFilterMaterial_itemSearch
                ...SbSharedRefineMenuFilterItemLocation_itemSearch
                ...SbSharedRefineMenuFilterShipsFrom_itemSearch
                ...SbSharedRefineMenuFilterDeliveryOptions_itemSearch
                ...SbSharedRefineMenuFilterItemType_itemSearch
                ...SbSharedRefineMenuFilterJewelryType_itemSearch
                ...SbRespRefineMenuFilterDimensions_itemSearch
                ...SbRespRefineMenuFilterCaratWeight_itemSearch
                ...SbMobileRefineMenuFilterDimensions_itemSearch
                ...SbMobileRefineMenuFilterCaratWeight_itemSearch
                ...SbSharedRefineMenuFilterAvailability_itemSearch
                ...SbSharedRefineMenuFilterMatchingSets_itemSearch
                ...SbSharedRefineMenuFilterGender_itemSearch
                ...SbSharedRefineMenuFilterFraming_itemSearch
                ...SbSharedRefineMenuFilterFramingOld_itemSearch
                ...SbSharedRefineMenuFilterRingSize_itemSearch
                ...SbMobileRefineMenuFilterArtSize_itemSearch
                ...SbRespRefineMenuFilterArtSize_itemSearch
                ...SbSharedRefineMenuFilterArtOrientation_itemSearch
                ...SbSharedRefineMenuFilterMovement_itemSearch
                ...useMeasurementUnit_itemSearch
                topCategoryL1
                appliedFilters {
                    name
                    values {
                        displayName
                        urlLabel
                    }
                }
            }
        `,
        filters: graphql`
            fragment SbSharedRefineMenuFilters_filters on SearchBrowseFilter @relay(plural: true) {
                name
                totalCount
                localizedFilterName
                originalFilterName
                ...SbRespRefineMenuFilterCategory_filters
                ...SbMobileRefineMenuFilterCategory_filters
                ...SbRespRefineMenuFilterDimensions_filters
                ...SbMobileRefineMenuFilterDimensions_filters
                ...SbRespRefineMenuFilterArtSize_filters
                ...SbMobileRefineMenuFilterArtSize_filters
                ...SbSharedRefineMenuFilterRingSize_filters
                values {
                    displayName
                    urlLabel
                    count
                    code
                    linkReference
                    linkable
                    hexCode
                    stats {
                        key
                        values
                    }
                    properties {
                        min
                        max
                    }
                }
            }
        `,
        user: graphql`
            fragment SbSharedRefineMenuFilters_user on User {
                ...SbRespRefineMenuFilterPrice_user
                ...SbRespRefineMenuFilterDimensions_user
                ...SbMobileRefineMenuFilterPrice_user
                ...SbMobileRefineMenuFilterDimensions_user
                ...SbMobileRefineMenuFilterArtSize_user
                ...SbRespRefineMenuFilterArtSize_user
                ...useMeasurementUnit_user
                ...SbSharedRefineMenuFilterDeliveryOptions_user
            }
        `,
    }
);
