import { useCallback, useEffect, useState } from 'react';
import { graphql, useFragment, useRelayEnvironment, fetchQuery } from 'react-relay';
import { getBuyerId } from 'dibs-cookie-jar';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { getUserGeoInfo } from 'dibs-regional-info/exports/regionalInfoHelpers';
import { getAbTestV2 } from 'dibs-ab-tests/src/clientAbTestV2';
import { getShippingCostSbVariant } from '../../utils/abTestHelper';

import {
    type useItemShippingPrices_item$data,
    type useItemShippingPrices_item$key,
} from './__generated__/useItemShippingPrices_item.graphql';
import { type useItemShippingPricesQuery } from './__generated__/useItemShippingPricesQuery.graphql';

const BATCH_SIZE = 12;

const ItemShippingPricesQuery = graphql`
    query useItemShippingPricesQuery(
        $items: [ItemShippingPriceItem]
        $buyerId: String
        $userZipCode: String
        $userCountryCode: String
        $prequoteBlockListTestArm: String
    ) {
        viewer {
            itemsShippingPrice(
                items: $items
                buyerId: $buyerId
                useLiveEndpoint: true
                zipCode: $userZipCode
                countryCode: $userCountryCode
                prequoteBlockListTestArm: $prequoteBlockListTestArm
            ) {
                itemId
                shipmentQuote {
                    buyerTotalAmount {
                        convertedAmountList {
                            currency
                            amount
                        }
                    }
                }
            }
        }
    }
`;
const itemFragment = graphql`
    fragment useItemShippingPrices_item on Item @relay(plural: true) {
        serviceId
        isOnHold
        isSold
        isUnavailable
        seller {
            isAccessible
        }
        shipmentQuotes {
            serviceMethod {
                region {
                    code
                }
            }
        }
    }
`;

export type ItemShippingPrices = Record<string, number>;

type FetchItemShippingPrices = (index: number) => void;
type GetItemShippingPrices = (itemId: string | null | undefined) => ItemShippingPrices | null;
export type ItemShippingPricesMap = { [itemId: string]: ItemShippingPrices };

export const useItemShippingPrices = (
    itemRef: useItemShippingPrices_item$key
): {
    fetchItemShippingPrices: FetchItemShippingPrices;
    getItemShippingPrices: GetItemShippingPrices;
} => {
    const items = useFragment(itemFragment, itemRef);
    const environment = useRelayEnvironment();
    const [attemptingBatches, setAttemptingBatches] = useState<number[]>([]);
    const [attemptedBatches, setAttemptedBatches] = useState<number[]>([]);
    const [itemShippingPricesMap, setItemShippingPricesMap] = useState<ItemShippingPricesMap>({});

    const getShippingPrices = useCallback(
        async (batchIndex: number): Promise<void> => {
            let shippingPricesMap: ItemShippingPricesMap = {};
            const start = batchIndex * BATCH_SIZE;
            const batch = items.slice(start, start + BATCH_SIZE).filter(item => item.serviceId);

            //Filtering out duplicate, already fetched and unavailable items
            const itemIds = [
                ...new Set(
                    batch
                        .filter(
                            ({ serviceId, isOnHold, isSold, isUnavailable, seller }) =>
                                serviceId &&
                                !itemShippingPricesMap[serviceId] &&
                                !(isOnHold || isSold || isUnavailable) &&
                                !!seller?.isAccessible
                        )
                        .map(item => item.serviceId)
                        .filter(filterFalsy)
                ),
            ];

            if (itemIds.length) {
                // `buyerId` is added to relay vars after user is fetched, no need to wait for full user data
                const buyerId = getBuyerId(document.cookie);
                const userGeo = getUserGeoInfo();
                const prequoteBlockListTestArm = getAbTestV2('prequoteBlockListTest')?.variant;

                const itemsMap = items.reduce((map, item) => {
                    if (item.serviceId) {
                        map.set(item.serviceId, item);
                    }
                    return map;
                }, new Map<string, useItemShippingPrices_item$data[number]>());

                const inputItems = itemIds.map(itemId => {
                    return {
                        itemId,
                        quantity: 1,
                        hasPrequote: (itemsMap.get(itemId)?.shipmentQuotes || []).some(
                            shipmentQuote => {
                                const code = shipmentQuote?.serviceMethod?.region?.code;
                                return code ? code !== 'LOCAL' : false;
                            }
                        ),
                    };
                });

                try {
                    const data = await fetchQuery<useItemShippingPricesQuery>(
                        environment,
                        ItemShippingPricesQuery,
                        {
                            items: inputItems,
                            buyerId,
                            userZipCode: userGeo?.zipCode,
                            userCountryCode: userGeo?.countryCode,
                            prequoteBlockListTestArm,
                        }
                    ).toPromise();
                    shippingPricesMap =
                        data?.viewer?.itemsShippingPrice?.reduce((map, itemShippingPrice) => {
                            const itemId = itemShippingPrice?.itemId;
                            if (itemId) {
                                const amountMap = (
                                    itemShippingPrice?.shipmentQuote?.buyerTotalAmount
                                        ?.convertedAmountList || []
                                ).reduce<ItemShippingPrices>((acc, convertedAmount) => {
                                    if (convertedAmount) {
                                        const { currency, amount } = convertedAmount;
                                        if (currency && typeof amount === 'number') {
                                            acc[currency] = amount;
                                        }
                                    }
                                    return acc;
                                }, {});
                                map[itemId] = amountMap;
                            }
                            return map;
                        }, shippingPricesMap) || {};
                } catch {
                    // do nothing
                }
            }

            setItemShippingPricesMap(prev => ({ ...prev, ...shippingPricesMap }));
        },
        [items, itemShippingPricesMap, environment]
    );

    // Making a primitive string out of itemIds so useEffect would fire on item and not reference change
    const itemIdsString = items
        .map(item => item?.serviceId)
        .filter(Boolean)
        .join(',');

    // Handle page/filter/sort change
    useEffect(() => {
        if (itemIdsString) {
            setAttemptingBatches([]);
            setAttemptedBatches([]);
        }
    }, [itemIdsString]);

    useEffect(() => {
        for (const batchIndex of attemptingBatches) {
            if (!attemptedBatches.includes(batchIndex)) {
                getShippingPrices(batchIndex);
                setAttemptedBatches(prev => [...prev, batchIndex]);
            }
        }
    }, [attemptedBatches, attemptingBatches, getShippingPrices]);

    const fetchItemShippingPrices: FetchItemShippingPrices = index => {
        // Don't do anything if not in a test
        if (!getShippingCostSbVariant()) {
            return;
        }
        const currentBatchIndex = Math.floor(index / BATCH_SIZE);
        setAttemptingBatches(prev =>
            prev.includes(currentBatchIndex) ? prev : [...prev, currentBatchIndex]
        );
    };

    const getItemShippingPrices: GetItemShippingPrices = useCallback(
        itemId => {
            return itemId && hasKey(itemShippingPricesMap, itemId)
                ? itemShippingPricesMap[itemId]
                : null;
        },
        [itemShippingPricesMap]
    );

    return { fetchItemShippingPrices, getItemShippingPrices };
};
