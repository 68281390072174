import { useCallback, useEffect, useState } from 'react';
import { graphql, useFragment, useRelayEnvironment, fetchQuery } from 'react-relay';
import { getBuyerId } from 'dibs-cookie-jar';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { type UrgencySignal } from '../../../utils/tracking/searchBrowse/ecommerceTracking';
import { useServerVarsContext } from '../../../global/ServerVarsContext/ServerVarsContext';

import { type useSharedUrgencySignalsQuery } from './__generated__/useSharedUrgencySignalsQuery.graphql';
import {
    type useSharedUrgencySignals_item$data,
    type useSharedUrgencySignals_item$key,
} from './__generated__/useSharedUrgencySignals_item.graphql';

const URGENCY_SIGNALS_BATCH_SIZE = 12;

const UrgencySignalsQuery = graphql`
    query useSharedUrgencySignalsQuery($items: [UrgencySignalItem], $userId: String) {
        viewer {
            itemUrgencySignals(items: $items, userId: $userId) {
                itemId
                urgencySignals {
                    urgencyInfoType
                    count
                    message
                }
            }
        }
    }
`;
const itemFragment = graphql`
    fragment useSharedUrgencySignals_item on Item @relay(plural: true) {
        serviceId
        isOnHold
        isSold
        isUnavailable
        itemType
    }
`;

type FetchItemUrgencySignals = (index: number) => void;
type GetUrgencySignal = (itemId: string | null | undefined) => UrgencySignal;
export type UrgencySignalsMap = { [itemId: string]: UrgencySignal };

export const useSharedUrgencySignals = (
    itemRef: useSharedUrgencySignals_item$key
): {
    fetchItemUrgencySignals: FetchItemUrgencySignals;
    getUrgencySignal: GetUrgencySignal;
} => {
    const { isMobile, urgencySignalsMwVariant } = useServerVarsContext();
    const items = useFragment(itemFragment, itemRef);
    const environment = useRelayEnvironment();
    const [attemptingBatches, setAttemptingBatches] = useState<number[]>([]);
    const [attemptedBatches, setAttemptedBatches] = useState<number[]>([]);
    const [urgencySignalsMap, setUrgencySignalsMap] = useState<UrgencySignalsMap>({});
    const shouldGetSignals = urgencySignalsMwVariant || !isMobile;

    const getUrgencySignals = useCallback(
        async (batchIndex: number): Promise<void> => {
            const signalsMap: UrgencySignalsMap = {};
            const start = batchIndex * URGENCY_SIGNALS_BATCH_SIZE;
            const batch = items
                .slice(start, start + URGENCY_SIGNALS_BATCH_SIZE)
                .filter(item => item.serviceId);

            //Filtering out duplicate, already fetched and unavailable items
            const itemIds = [
                ...new Set(
                    batch
                        .filter(
                            ({ serviceId, isOnHold, isSold, isUnavailable }) =>
                                serviceId &&
                                !urgencySignalsMap[serviceId] &&
                                !(isOnHold || isSold || isUnavailable)
                        )
                        .map(item => item.serviceId)
                        .filter(filterFalsy)
                ),
            ];

            if (shouldGetSignals && itemIds.length) {
                // `userId` is added to relay vars after user is fetched, no need to wait for full user data
                const userId = getBuyerId(document.cookie);

                const itemsMap = items.reduce((map, item) => {
                    if (item.serviceId) {
                        map.set(item.serviceId, item);
                    }
                    return map;
                }, new Map<string, useSharedUrgencySignals_item$data[number]>());

                const inputItems = itemIds.map(itemId => ({
                    itemId,
                    itemType: itemsMap.get(itemId)?.itemType || null,
                }));

                try {
                    const data = await fetchQuery<useSharedUrgencySignalsQuery>(
                        environment,
                        UrgencySignalsQuery,
                        { items: inputItems, userId }
                    ).toPromise();
                    data?.viewer?.itemUrgencySignals?.forEach(signal => {
                        const itemId = signal?.itemId;
                        if (itemId) {
                            const {
                                urgencyInfoType: type,
                                count,
                                message,
                            } = signal?.urgencySignals || {};
                            signalsMap[itemId] =
                                type && message && count ? { type, message, count } : null;
                        }
                    });
                } catch {
                    // no nothing
                }
            }
            // In case call fails and signalsMap was not filled with data, or some items are unpurchasable so their data was not fetch at all, we still want to indicate that fetching data is finished.
            // So create newSignals object using original itemIds
            const newSignals = batch
                .map(item => item.serviceId)
                .reduce((obj, itemId) => {
                    return itemId ? { ...obj, [itemId]: signalsMap[itemId] || null } : obj;
                }, <UrgencySignalsMap>{});

            setUrgencySignalsMap(prev => ({ ...prev, ...newSignals }));
        },
        [environment, items, shouldGetSignals, urgencySignalsMap]
    );

    // Making a primitive string out of itemIds so useEffect would fire on item and not reference change
    const itemIdsString = items
        .map(item => item?.serviceId)
        .filter(Boolean)
        .join(',');

    // Handle page/filter/sort change
    useEffect(() => {
        if (itemIdsString) {
            setAttemptingBatches([]);
            setAttemptedBatches([]);
        }
    }, [itemIdsString]);

    useEffect(() => {
        for (const batchIndex of attemptingBatches) {
            if (!attemptedBatches.includes(batchIndex)) {
                getUrgencySignals(batchIndex);
                setAttemptedBatches(prev => [...prev, batchIndex]);
            }
        }
    }, [attemptedBatches, attemptingBatches, getUrgencySignals]);

    const fetchItemUrgencySignals: FetchItemUrgencySignals = index => {
        const currentBatchIndex = Math.floor(index / URGENCY_SIGNALS_BATCH_SIZE);
        setAttemptingBatches(prev =>
            prev.includes(currentBatchIndex) ? prev : [...prev, currentBatchIndex]
        );
    };

    const getUrgencySignal: GetUrgencySignal = useCallback(
        itemId => {
            return itemId && hasKey(urgencySignalsMap, itemId) ? urgencySignalsMap[itemId] : null;
        },
        [urgencySignalsMap]
    );

    return { fetchItemUrgencySignals, getUrgencySignal };
};
