/**
 * @generated SignedSource<<7a1d71e4aba51a36767ba60be6ae695c>>
 * @relayHash 796a38681448c65be83dd109b5c13fd4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/505.0.0-2025-04-08T14:45:28.242Z/useSharedUrgencySignalsQuery

import { ConcreteRequest } from 'relay-runtime';
export type urgencyType = "CART" | "CONVERSATIONS" | "OFFERS" | "SOLD" | "VIEWS" | "%future added value";
export type UrgencySignalItem = {
  itemId?: string | null;
  itemType?: string | null;
};
export type useSharedUrgencySignalsQuery$variables = {
  items?: ReadonlyArray<UrgencySignalItem | null> | null;
  userId?: string | null;
};
export type useSharedUrgencySignalsQuery$data = {
  readonly viewer: {
    readonly itemUrgencySignals: ReadonlyArray<{
      readonly itemId: string | null;
      readonly urgencySignals: {
        readonly count: number | null;
        readonly message: string | null;
        readonly urgencyInfoType: urgencyType | null;
      } | null;
    } | null> | null;
  };
};
export type useSharedUrgencySignalsQuery = {
  response: useSharedUrgencySignalsQuery$data;
  variables: useSharedUrgencySignalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "items"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "items",
      "variableName": "items"
    },
    {
      "kind": "Variable",
      "name": "userId",
      "variableName": "userId"
    }
  ],
  "concreteType": "ItemUrgencySignalsType",
  "kind": "LinkedField",
  "name": "itemUrgencySignals",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemUrgencyInfoType",
      "kind": "LinkedField",
      "name": "urgencySignals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urgencyInfoType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSharedUrgencySignalsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSharedUrgencySignalsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/505.0.0-2025-04-08T14:45:28.242Z/useSharedUrgencySignalsQuery",
    "metadata": {},
    "name": "useSharedUrgencySignalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2cd9c7705d6f72adaec08e8a862f9b94";

export default node;
