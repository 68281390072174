import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import * as tracking from 'dibs-tracking';

import { SharedPaginationComponent } from './SharedPaginationComponent';

import { updateUriRef as updateUriRefAction } from '../../actions/filterActions';
import {
    PAGINATION_TOTAL_LINKS_DESKTOP,
    PAGINATION_TOTAL_LINKS_TABLET_LANDSCAPE,
    PAGINATION_TOTAL_LINKS_TABLET_PORTRAIT,
    WIDTH_DESKTOP,
    WIDTH_TABLET_LANDSCAPE,
} from './sharedPaginationConstants';

class SharedPaginationComponentComponent extends Component {
    constructor() {
        super();

        this.onResize = debounce(this.onResize.bind(this), 500, { leading: true });
        this.onLinkClick = this.onLinkClick.bind(this);

        this.state = {
            totalPageLinks: PAGINATION_TOTAL_LINKS_DESKTOP,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);

        this.setState({ totalPageLinks: this.getTotalPageLinks() });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.setState({ totalPageLinks: this.getTotalPageLinks() });
    }

    getTotalPageLinks() {
        const width = window.innerWidth;
        let totalPageLinks = PAGINATION_TOTAL_LINKS_DESKTOP;

        if (width <= WIDTH_TABLET_LANDSCAPE) {
            totalPageLinks = PAGINATION_TOTAL_LINKS_TABLET_PORTRAIT;
        } else if (width <= WIDTH_DESKTOP) {
            totalPageLinks = PAGINATION_TOTAL_LINKS_TABLET_LANDSCAPE;
        }

        return totalPageLinks;
    }

    onLinkClick({ e, href, pageNumber }) {
        e.preventDefault();
        this.props.updateUriRef({ uriRef: href });
        window.scroll(0, 0);
        tracking.trackEvent(
            {
                category: 'navigation',
                action: 'pagination page number clicked',
                label: `${pageNumber}/${this.props.numberOfPages}`,
            },
            e
        );
    }

    render() {
        const { currentPage, numberOfPages, displayUriRef } = this.props;

        return (
            <SharedPaginationComponent
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                uriRef={displayUriRef}
                totalPageLinks={this.state.totalPageLinks}
                onLinkClick={this.onLinkClick}
                hideHref={this.props.hideHref}
            />
        );
    }
}

SharedPaginationComponentComponent.propTypes = {
    currentPage: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    displayUriRef: PropTypes.string.isRequired,
    updateUriRef: PropTypes.func.isRequired,
    hideHref: PropTypes.bool,
};

export const SharedPagination = connect(null, { updateUriRef: updateUriRefAction })(
    SharedPaginationComponentComponent
);
