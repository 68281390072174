/**
 * @generated SignedSource<<6bf2d95ca912c933586ca906a5a61614>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useItemShippingPrices_item$data = ReadonlyArray<{
  readonly isOnHold: boolean | null;
  readonly isSold: boolean | null;
  readonly isUnavailable: boolean | null;
  readonly seller: {
    readonly isAccessible: boolean | null;
  } | null;
  readonly serviceId: string | null;
  readonly shipmentQuotes: ReadonlyArray<{
    readonly serviceMethod: {
      readonly region: {
        readonly code: string | null;
      } | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "useItemShippingPrices_item";
}>;
export type useItemShippingPrices_item$key = ReadonlyArray<{
  readonly " $data"?: useItemShippingPrices_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useItemShippingPrices_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useItemShippingPrices_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnHold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnavailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAccessible",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentQuote",
      "kind": "LinkedField",
      "name": "shipmentQuotes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShipmentServiceMethod",
          "kind": "LinkedField",
          "name": "serviceMethod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Region",
              "kind": "LinkedField",
              "name": "region",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "587ecfe35dd8ffe114b25ca37e93b248";

export default node;
